import { Id } from '@eo-storefronts/eo-core'
import { useEffect, useRef, useState } from 'react'
import OrderDetailContainer from '~/src/components/orders/OrderDetailContainer'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { Constants } from '~/src/helpers/constants'
import { useFetchAllOrders } from '~/src/hooks/orders/useFetchAllOrders'
import { useFetchOrderDetail } from '~/src/hooks/orders/useFetchOrderDetail'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { orderIsPickedUp } from '~/src/services/OrderService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { LAST_GUEST_ORDER_ID_STATE, MOST_RECENT_ORDER_SELECTOR, ORDER_DETAILS_STATE } from '~/src/stores/orders'
import useGetOrderTemplate from '~/src/components/orders/order-layout/styles/useGetOrderTemplate'
import InPageLoader from '~/src/components/loader/InPageLoader'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'
import SlideFromBottom from '~/src/components/mui-wrappers/animations/motion/SlideFromBottom'
import { useLocation } from 'react-router-dom'

const Upcoming = () => {
  const { pathname } = useLocation()
  const { loggedInAs } = useEoValue(AUTHENTICATION_STATE)
  const [ firstFetch, setFirstFetch ] = useState<boolean>(true)
  const order = useEoValue(MOST_RECENT_ORDER_SELECTOR)
  const setOrderDetails = useSetEoState(ORDER_DETAILS_STATE)
  const customer = useEoValue(CUSTOMER_STATE)
  const lastGuestOrderId = useEoValue(LAST_GUEST_ORDER_ID_STATE)
  const { fetch: fetchOrderDetail, loading: isFetchingOrderDetail } = useFetchOrderDetail()
  const { fetch: fetchAllOrders, loading: isFetchingAllOrders } = useFetchAllOrders()
  const interval = useRef<NodeJS.Timer>()

  useGetOrderTemplate()

  const _getOrderId = (): Id | null | undefined => {
    if (!loggedInAs) {
      return
    }

    return loggedInAs === 'guest' ? lastGuestOrderId : order?.id
  }

  const _getIsLoading = (): boolean => {
    return isFetchingAllOrders || isFetchingOrderDetail || firstFetch
  }


  useEffect(() => {
    return () => {
      clearInterval(interval.current!)
      setOrderDetails(null)
    }
  }, [])

  useAsyncEffect(async () => {
    clearInterval(interval.current!)

    await Promise.all([
      fetchOrderDetail(_getOrderId(), customer, true),
      fetchAllOrders(customer?.id)
    ])

    if (order && !orderIsPickedUp(order.status)) {
      interval.current = setInterval(async () => {
        await fetchOrderDetail(order.id, customer, false)
      }, Constants.FETCH_ORDER_DETAIL_INTERVAL)
    }
    setFirstFetch(false)
  }, [ customer, order?.id, lastGuestOrderId ])

  return (
    <>
      <DocumentTitleInjector pageName={'UPCOMING'}/>

      {_getIsLoading() && <InPageLoader sx={{ mt: 0 }}/>}
      {!_getIsLoading() && (
        <SlideFromBottom
          pathname={pathname}
          y={10}>
          <ContentContainer sx={{ mb: 2 }}>
            <OrderDetailContainer orderSummary={order}/>
          </ContentContainer>
        </SlideFromBottom>
      )}
    </>
  )
}

export default Upcoming
